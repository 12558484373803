import React, { useState, useRef, useEffect } from "react";
import "../assets/css/abdm.css";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import eye from "../assets/image/abha/eye.png";

const Communication = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [refmobileNumber, setRefMobileNumber] = useState('');
  const [countdown, setCountdown] = useState(60); // Initial countdown time in seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const history = useHistory();
  const refs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]; // Refs for each input field

  useEffect(() => {
    // Countdown logic
    if (countdown > 0) {
      const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const handleOtpChange = (index, value) => {
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        refs[index + 1].current.focus();
      }
    }
  };

  const handleNext = async () => {
    const otpCode = otp.join('');

    const authToken = localStorage.getItem("authToken");
    const mob_txnID = localStorage.getItem("mobile_verify_txnID");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    if (!authToken || !mob_txnID) {
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "Missing authentication token or transaction ID.",
      });
      return;
    }

    try {
      const response_mob_verify = await axios.post(`${baseUrl}/verifyMobileOTP`,
        {
          otp: otpCode,
          txnId: mob_txnID,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );

      const response = await axios.post(`${baseUrl}/createHealthIdWithPreVerified`,
        {
          txnId: response_mob_verify.data.data.txnId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      localStorage.setItem("abhaCardDetails", JSON.stringify(response.data.data));

      Swal.fire({
        icon: "success",
        title: "ABHA Checked",
        text: "ABHA has been successfully checked.",
      }).then(() => {
        history.push("/abha-card");
      });

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Verification Failed",
        text: "Failed to verify ABHA. Please try again.",
      });
      console.error("Error verifying ABHA:", error);
    }
  };

  const handleResendOtp = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      const mob_txnID = localStorage.getItem("mobile_verify_txnID");
      const baseUrl = process.env.REACT_APP_BASE_URL;

      await axios.post(`${baseUrl}/generateOtpMobile`,
        {
          txnId: mob_txnID
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );

      setCountdown(60); // Reset the countdown to 60 seconds
      setIsResendDisabled(true); // Disable the resend button again
      Swal.fire({
        icon: 'success',
        title: 'OTP Resent',
        text: 'A new OTP has been sent to your mobile number.',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Resend Failed',
        text: 'Failed to resend OTP. Please try again.',
      });
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <div className="container container-abdm">
      <div className="row main-box">
        <div className="col-md-8">
          <div className="left-content">
            <div className="input-field">
              <span className="subtitle">Confirm OTP</span>
              <p>
                OTP sent to Aadhaar mobile number ending with {refmobileNumber}
              </p>
              <div className="abdm-aadhar">
                <div className="input-fields-text">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "5px",
                        textAlign: "center",
                      }}
                      ref={refs[index]} // Assign ref to the input field
                    />
                  ))}
                  <img src={eye} alt="eye icon" />
                </div>
                <div className="generate-otp-msg">
                  {/* <p>Didn’t receive the OTP?</p> */}
                  <div className="otp-actions">
                    {/* <button
                      onClick={handleResendOtp}
                      className="btn btn-link resend-btn"
                      disabled={isResendDisabled}
                    >
                      Resend OTP
                    </button>
                    {isResendDisabled && (
                      <span className="countdown-text">
                        {countdown} sec remaining.
                      </span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="buttons-div">
              <button onClick={handleNext} className="btn btn-primary btn-dark">
                Next
              </button>
              <Link to="/otp-verify" className="btn btn-primary btn-light">
                Cancel
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="right-btn-sec">
            <button className="btn-success-dark">
              <span className="step-number">1</span>Consent Collection
            </button>
            <button className="btn-success-dark">
              <span className="step-number">2</span>Aadhaar Authentication
            </button>
            <button className="btn-active">
              <span className="step-number">3</span>Communication Details
            </button>
            <button className="btn-inactive">
              <span className="step-number">4</span>ABHA Address Creation
            </button>
          </div>
        </div>
      </div>
      <style>
        {`
          .generate-otp-msg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
          }

          .otp-actions {
            display: flex;
            align-items: center;
          }

          .resend-btn {
            margin-top: -16px;
            text-decoration: underline;
            margin-right: 150px;
          }

          .resend-btn:disabled {
            text-decoration: none;
          }

          .countdown-text {
            color: #333;
            margin-left: -50px;
            font-size: 15px;
            margin-top: -17px;
          }
        `}
      </style>
    </div>
  );
};

export default Communication;
