import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import eye from "../assets/image/abha/eye.png";
import closeEye from "../assets/image/abha/close-eye.png";

const AadharForm = ({ onGenerateOtp, setAadharSegments, setIsValid, setAuthToken }) => {
  const [aadharSegments, setLocalAadharSegments] = useState(["", "", ""]);
  const [isVisible, setIsVisible] = useState(false);
  const refs = [useRef(null), useRef(null), useRef(null)]; // Refs for each input field
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Fetch the auth token when the component mounts
    axios.get(`${baseUrl}/authToken`)
      .then(response => {
        setAuthToken(response.data.data.accessToken); // Assuming the token is in response.data.token
        localStorage.setItem("authToken", response.data.data.accessToken);
      })
      .catch(error => {
        console.error("Error fetching auth token:", error);
      });
  }, [baseUrl, setAuthToken]);

  const handleSegmentChange = (index, value) => {
    const updatedSegments = [...aadharSegments];
    updatedSegments[index] = value;
    setLocalAadharSegments(updatedSegments);
    setAadharSegments(updatedSegments);

    const isValidFirstSegment = /^[2-9]\d{3}$/.test(updatedSegments[0]); // Check first segment
    const isAllSegmentsValid = updatedSegments.every((segment, idx) =>
      idx === 0 ? isValidFirstSegment : /^\d{4}$/.test(segment)
    );

    setIsValid(isAllSegmentsValid);

    // Move focus to the next segment input field if four digits are entered
    if (value.length === 4 && index < aadharSegments.length - 1) {
      refs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && aadharSegments[index] === "" && index > 0) {
      refs[index - 1].current.focus();
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <form className="abdm-aadhar">
      <div className="input-fields-text">
        {aadharSegments.map((segment, index) => (
          <React.Fragment key={index}>
            <input
              type={isVisible ? "text" : "password"}
              value={segment}
              maxLength="4"
              inputMode="numeric"
              onChange={(e) => handleSegmentChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              placeholder={isVisible ? "0000" : "0000"}
              className="input-text"
              ref={refs[index]} // Assign ref to the input field
            />
            {index !== aadharSegments.length - 1 && <span>-</span>}
          </React.Fragment>
        ))}
        <div onClick={toggleVisibility}>
          <img src={isVisible ? eye : closeEye} alt="eye icon" />
        </div>
      </div>
    </form>
  );
};

export default AadharForm;




