import React from 'react'
import "../assets/css/abdm.css";
import { Link } from "react-router-dom";

const Createaccount = () => {
  return (
    <div className="container container-abdm ">
    <div className="row main-box">
      <div className="col-md-8">
        <div className="left-content" >
         
          <div className="input-field">
            <span className="subtitle">Create ABHA Number Using Aadhaar.</span>
            <p>ABHA (Ayushman Bharat Health Account) address is a unique username that allow you to share and access your health record digitally, It is similar to an email address, but it is only use for health records.</p>
            <p>to create ABHA address, it should have Min-8 characters, Max-18 characters special characters allowed 1 dot (.) and/or 1 underscore (_).</p>
            <form action="#" className="abdm-aadhar">
                <label htmlFor="emailId">Enter ABHA Address.</label>
              <input
                type="text"
                placeholder="abc@abdm.com"
                className="input-box"
              />
            </form>
          </div>
       
          <div className="buttons-div">
            <Link to="/communication-detail" className="btn btn-primary btn-darkbtn btn-primary btn-light">
              Cancel
            </Link>
            <Link to="/" className="btn btn-primary btn-dark">
              Create
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="right-btn-sec">
          <button className="btn-success-dark">
            <span className="step-number">1</span>Consent Collection
          </button>
          <button className="btn-success-dark">
            <span className="step-number">2</span>Aadhaar Authentication
          </button>
          <button className="btn-success-dark">
            <span className="step-number">3</span>Communication Details
          </button>
          <button className="btn-active">
            <span className="step-number">4</span>BHA Address Creation
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Createaccount;