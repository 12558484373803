import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useHistory } from "react-router-dom";
import "../assets/css/abdm.css";

const OtpVerify = () => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [mobileNumber, setMobileNumber] = useState('');
    const [refmobileNumber, setRefMobileNumber] = useState('');
    const [imageData, setImageData] = useState(null);

    const [authToken, setAuthToken] = useState(null);
    const [txnID, setTxnID] = useState(null);
    const [countdown, setCountdown] = useState(60); // Initial countdown time in seconds
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    const refs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const history = useHistory();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        const fetchAuthToken = async () => {
            try {
                const response = await axios.get(`${baseUrl}/authToken`);
                const token = response.data.data.accessToken;
                const transactionID = response.data.data.txnID;
                setAuthToken(token);
                setTxnID(transactionID);
                localStorage.setItem("authToken", token);
                localStorage.setItem("txnID", transactionID);
            } catch (error) {
                console.error("Error fetching auth token:", error);
            }
        };

        const storedAuthToken = localStorage.getItem("authToken");
        const storedTxnID = localStorage.getItem("txnID");
        const rmobileNumber = localStorage.getItem("mobileNumber");
        setRefMobileNumber(rmobileNumber);

        if (storedAuthToken && storedTxnID) {
            setAuthToken(storedAuthToken);
            setTxnID(storedTxnID);
        } else {
            fetchAuthToken();
        }
    }, [baseUrl]);

    useEffect(() => {
        // Countdown logic
        if (countdown > 0) {
            const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timerId);
        } else {
            setIsResendDisabled(false);
        }
    }, [countdown]);

    const handleOtpChange = (index, value) => {
        if (value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value && index < otp.length - 1) {
                refs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && otp[index] === "") {
            if (index > 0) {
                refs[index - 1].current.focus();
                const newOtp = [...otp];
                newOtp[index - 1] = "";
                setOtp(newOtp);
            }
        }
    };

    const handleChange = (e) => {
        const input = e.target.value;
        if (!/^\d*$/.test(input)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Input',
                text: 'Please enter only numeric characters.',
            });
        } else {
            setMobileNumber(input);
        }
    };
    // alert(isHidden);
    const handleNext = async () => {
        const otpCode = otp.join('');
        if (!authToken || !txnID || otpCode.length !== 6) {
            Swal.fire({
                icon: 'error',
                title: 'Incomplete Data',
                text: 'Please enter a valid OTP and ensure all data is fetched correctly.',
            });
            return;
        }
        
        // const resend_otp_txnID = localStorage.getItem("resend_otp_txnID");
        try {
            const response = await axios.post(`${baseUrl}/verifyAadhaarOtp`,
                {
                    otp: otpCode,
                    txnId: txnID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            );
            setIsHidden(!isHidden);
            const imageData = `data:image/jpeg;base64,${response.data.data.photo}`;
            setImageData(imageData);
            localStorage.setItem('abhaProfileImage', imageData);

            const response_mobile = await axios.post(`${baseUrl}/generateOtpMobile`,
                {
                    mobile: mobileNumber,
                    txnId: response.data.data.txnId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                }
            );

            localStorage.setItem("mobile_verify_txnID", response_mobile.data.data.txnId);

            Swal.fire({
                icon: 'success',
                title: 'OTP Verified',
                text: 'OTP has been successfully verified.',
            }).then(() => {
                history.push("/communication-detail");
            });
        } catch (error) {
            console.error("Error verifying OTP:", error);
            Swal.fire({
                icon: 'error',
                title: 'Verification Failed',
                text: `Failed to verify OTP. ${error.response?.data?.message || 'Please try again later.'}`,
            });
        }
    };



    // const handleResendOtp = async () => {
    //     const storedAadharNumber = localStorage.getItem("aadharNumber"); // Retrieve Aadhaar number from localStorage
    //     const storedTxnID = localStorage.getItem("txnID");

    //     try {
    //         const response = await axios.post(`${baseUrl}/generateOtpAdhaar`,
    //             {
    //                 aadharNumber: storedAadharNumber

    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${authToken}`
    //                 }
    //             }
    //         );
    //         localStorage.setItem("txnID", response.data.data.txnId);
    //         setCountdown(6); // Reset the countdown to 60 seconds
    //         setIsResendDisabled(true); // Disable the resend button again
    //         Swal.fire({
    //             icon: 'success',
    //             title: 'OTP Resent',
    //             text: 'A new OTP has been sent to your Aadhaar-linked mobile number.',
    //         });
    //     } catch (error) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Resend Failed',
    //             text: 'Failed to resend OTP. Please try again.',
    //         });
    //         console.error("Error resending OTP:", error);
    //     }
    // };



    return (
        <div className="container container-abdm">
            <div className="row main-box">
                <div className="col-md-8">
                    <div className="left-content">
                        <div className="input-field">
                            <span className="subtitle">Confirm OTP</span>
                            <p>OTP sent to Aadhaar mobile number ending with {refmobileNumber}</p>
                            {/* <img src={imageData} alt="profile-img" /> */}
                            <div className="abdm-aadhar">
                                <div className="input-fields-text">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                marginRight: "5px",
                                                textAlign: "center",
                                            }}
                                            ref={refs[index]}
                                        />
                                    ))}
                                    {/* <img src={eye} alt="eye icon" /> */}
                                </div>
                                <div className="generate-otp-msg">
                                    {/* <p>Didn’t receive the OTP?</p> */}
                                    <div className="otp-actions">
                                        {/* <button 
            onClick={handleResendOtp} 
            className="btn btn-link resend-btn" 
            disabled={isResendDisabled}
        >
            Resend OTP
        </button>
        {isResendDisabled && (
            <span className="countdown-text">
             {countdown} sec remaining.
            </span>
        )} */}
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    maxLength={10}
                                    placeholder="Mobile Number*"
                                    className="input-box"
                                    value={mobileNumber}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <p>This mobile number will be used for all the communications related to ABHA.</p>
                        <div className="agree-text">
                            <input type="checkbox" id="agreeCheckbox" />
                            <label htmlFor="agreeCheckbox">I agree</label>
                        </div>
                        <div className="buttons-div">

                            <button
                                onClick={handleNext}
                                className="btn btn-primary btn-dark"
                            >
                                Next
                            </button>
                            <Link to="/abdm" className="btn btn-primary btn-light">
                                Cancel
                            </Link>
                        </div>
                        <div className="text-success" role="alert" style={{ display: !isHidden ? 'none' : 'block' }}>
                           OTP send successfully!
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="right-btn-sec">
                        <button className="btn-success-dark">
                            <span className="step-number">1</span>Consent Collection
                        </button>
                        <button className="btn-active">
                            <span className="step-number">2</span>Aadhaar Authentication
                        </button>
                        <button className="btn-inactive">
                            <span className="step-number">3</span>Communication Details
                        </button>
                        <button className="btn-inactive">
                            <span className="step-number">4</span>BHA Address Creation
                        </button>
                    </div>
                </div>
            </div>
            <style> {`
    .generate-otp-msg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .otp-actions {
        display: flex;
        align-items: center;
    }

    .resend-btn {
         margin-top: -17px;
        text-decoration: underline;
        margin-right: 150px;
    }

    .resend-btn:disabled {
        text-decoration: none;
    }

    .countdown-text {
        color: #333;
        margin-left: -50px;
        font-size: 15px;
          margin-top: -17px;
    }
        `}
            </style>
        </div>
    );
};

export default OtpVerify;
