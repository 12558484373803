import React, { useState } from "react";
import "../assets/css/abdm.css";
import { useHistory } from "react-router-dom";
import AadharForm from "./AadharForm";
import axios from "axios";

const Abdm = () => {
  const history = useHistory();
  const [aadharSegments, setAadharSegments] = useState(["", "", ""]);
  const [isValid, setIsValid] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleGenerateOtp = (aadharNumber, authToken) => {
    localStorage.setItem("aadharNumber", aadharNumber); // Store Aadhaar number in localStorage

    axios.post(`${baseUrl}/generateOtpAdhaar`,
      {
        aadharNumber: aadharNumber
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + authToken
        }
      }
    )
    .then(response => {
      localStorage.setItem("txnID", response.data.data.txnId);
      localStorage.setItem("mobileNumber", response.data.data.mobileNumber);
      localStorage.setItem("aadharNumber", aadharNumber);

      history.push('/otp-verify'); // Redirect to OTP verify page
    })
    .catch(error => {
      console.error("Error generating OTP:", error);
    });
};


  const handleCancel = () => {
    // Reset all state values to their initial state
    setAadharSegments(["", "", ""]);
    setIsValid(false);
    setAuthToken(null);
    // Optionally, reload the page
    window.location.reload();
  };

  return (
    <div className="container container-abdm ">
      <div className="row main-box">
        <div className="col-md-8">
          <div className="left-content">
            <div className="input-field">
              <span className="subtitle">Enter Aadhaar Number</span>
              <AadharForm 
                onGenerateOtp={handleGenerateOtp} 
                setAadharSegments={setAadharSegments} 
                setIsValid={setIsValid}
                setAuthToken={setAuthToken}
              />
            </div>
            <p>
              <span className="red-start">*</span>Please ensure that mobile
              number is linked with Aadhaar as it will be required for OTP
              authentication.
            </p>
            <div className='para' style={{ overflowY: 'scroll' }}>
              <p className='fw-bold mt-2 mb-2'> Terms and Conditions</p>
              I, hereby declare that I am voluntarily sharing my Aadhaar number
              and demographic information issued by UIDAI, with National Health
              Authority (NHA) for the sole purpose of creation of ABHA number. I
              understand that my ABHA number can be used and shared for purposes
              as may be notified by ABDM from time to time including provision
              of healthcare services. Further, I am aware that my personal
              identifiable information (Name, Address, Age, Date of Birth,
              Gender and Photograph) may be made available to the entities
              working in the National Digital Health Ecosystem (NDHE) which
              inter alia includes stakeholders and entities such as healthcare
              professionals (e.g. doctors), facilities (e.g. hospitals,
              laboratories) and data fiduciaries (e.g. health programmes), which
              are registered with or linked to the Ayushman Bharat Digital
              Mission (ABDM), and various processes there under. I authorize NHA
              to use my Aadhaar number for performing Aadhaar based
              authentication with UIDAI as per the provisions of the Aadhaar
              (Targeted Delivery of Financial and other Subsidies, Benefits and
              Services) Act, 2016 for the aforesaid purpose. I understand that
              UIDAI will share my e-KYC details, or response of “Yes” with NHA
              upon successful authentication. I have been duly informed about
              the option of using other IDs apart from Aadhaar; however, I
              consciously choose to use Aadhaar number for the purpose of
              availing benefits across the NDHE. I am aware that my personal
              identifiable information excluding Aadhaar number / VID number can
              be used and shared for purposes as mentioned above. I reserve the
              right to revoke the given consent at any point of time as per
              provisions of Aadhaar Act and Regulations.
            </div>
            <div className="agree-text">
              <input type="checkbox" id="agreeCheckbox" />
              <label htmlFor="agreeCheckbox">I agree</label>
            </div>
            <div className="buttons-div">
              <button 
                onClick={() => handleGenerateOtp(aadharSegments.join(""), authToken)} 
                className="btn btn-primary btn-dark" 
                disabled={!isValid}
              >
                Next
              </button>
              <button onClick={handleCancel} className="btn btn-primary btn-light">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="right-btn-sec">
            <button className="btn-active"><span className="step-number">1</span>Consent Collection</button>
            <button className="btn-inactive"><span className="step-number">2</span>Aadhaar Authentication</button>
            <button className="btn-inactive"><span className="step-number">3</span>Communication Details</button>
            <button className="btn-inactive"><span className="step-number">4</span>BHA Address Creation</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abdm;
