import { BrowserRouter as Router, Route,Switch} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
// import abdmPage from "./Pages/abdmPage";
import '../src/assets/css/style.css';
import '../src/assets/css/headers/new-style.css';

// import Createaccount from "./Pages/Createaccount";
import Abdm from "./Pages/Abdm";
import OtpVerify from "./Pages/OtpVerify";

import Createaccount from "./Pages/Createaccount";
import Communication from "./Pages/Communication";
import AbhaCard from "./Pages/AbhaCard";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
      <Route exact path="/" component={Abdm} />
        <Route exact path="/abdm" component={Abdm} />
        <Route path="/otp-verify" component={OtpVerify} />
        <Route path="/communication-detail" component={Communication} /> 
        <Route path="/create-account" component={Createaccount} /> 
        <Route path="/abha-card" component={AbhaCard} /> 
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
