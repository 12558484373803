import React from "react";
import { Link } from 'react-router-dom';

import ligthLogo from '../assets/img/light-logo.png';
import startuplogo from '../images/home/banner/startup-logo.svg';
import xicon from '../images/x-light-icon.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faLinkedin,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <footer id="footer" className="footer-main">
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4 col-6">
                  <div className="footer-1">
                    <h5 className="widget-title">
                      <span>Services</span>
                    </h5>
                    <ul className="thumbnail-widget">
                      <li>
                        <Link to="#">Claim Assistance</Link>
                      </li>
                      <li>
                        <Link to="#">Claim Rejection</Link>
                      </li>
                      <li>
                        <Link to="#">Policy Decoding</Link>
                      </li>
                      <li>
                        <Link to="#">Claim Calculator</Link>
                      </li>
                      <li>
                        <Link to="#">Legal Assistance</Link>
                      </li>
                      <li>
                        <Link to="#">Know your policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="footer-1">
                    <h5 className="widget-title">
                      <span>Quick links</span>
                    </h5>
                    <ul className="thumbnail-widget">
                      <li>
                        <Link to="/about-us">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="#">Testimonial</Link>
                      </li>
                      <li>
                        <Link to="#">Media Coverage</Link>
                      </li>
                      <li>
                        <Link to="#">Career</Link>
                      </li>
                      <li>
                        <Link to="/partners">
                          Partnership
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer-1 f-contact">
                    <h5 className="widget-title">
                      <span>Get in touch</span>
                    </h5>
                    <p>We’re here to listen:</p>
                    <div className="container">
                      <div className="row">
                        <div className="footer-cl">
                          <i className="fa fa-building" aria-hidden="true"></i>
                        </div>
                        <div className="footer-cr">
                        E -2/189, 2nd Floor, Shastri Nagar, Delhi 110052
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <div className="container">
                      <div className="row">
                        <div className="footer-cl">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                        </div>

                        <div className="footer-cr">
                          <Link to="tel:+919310121772">+91 931-012-1772</Link>
                        </div>
                      </div>
                    </div>
                    <p></p>
                    <div className="container">
                      <div className="row">
                        <div className="footer-cl">
                          <i className="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div className="footer-cr">
                          {" "}
                          <Link to="mailto:info@easeclaim.com">
                            info@easeclaim.com
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-2">
                <h5 className="widget-title">
                  <span>Newsletter</span>
                </h5>
                <p>
                  Receive resources & tools that can help you prepare for the
                  future. You can cancel anytime.
                </p>
                <div className="news-form">
                  <form action="#" method="post" id="newsletters-form">
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        id="inputAddress"
                        placeholder="YOUR MAIL HERE"
                      />
                    </div>
                    <div className="form-group col-md-12 subscribe-btn">
                      <button type="submit" className="btn btn-secondry">
                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                        Subscribe Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-left">
                <p>
                  <span className="text-left">
                    © 2023, easeclaim <br />
                    All Rights Reserved by Metagates Innovation Pvt. Ltd.
                  </span>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p>
                  {" "}
                  <Link to="/">
                    <img
                      src={ligthLogo}
                      alt="easeclaim logo"
                    />
                  </Link>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <div className="startup-logo">
                  <img
                    src={startuplogo}
                    alt="startuplogo logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="contain-bottom">
                  <span className="text-left">
                    <ul className="social-footer">
                      <li>
                        <Link to="https://www.facebook.com/profile.php?id=100094298285693">
                        <FontAwesomeIcon icon={faFacebook} size="48px"/>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            className="x-icon"
                            src={xicon}
                            width="15"
                            alt="easeclaim Twitter"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/company/ease-claim">
                        <FontAwesomeIcon icon={faLinkedin} size="48px"/>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/easeclaim/">
                        <FontAwesomeIcon icon={faInstagram} size="48px"/>
                        </Link>
                      </li>
                    </ul>
                  </span>
                  <span className="text-right">
                    <Link to="/privacy-policy">
                      Privacy Policy
                    </Link>
                    <Link to="/terms-and-conditions">
                      Terms & Condition
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
      
        .footer-1 .thumbnail-widget {
          list-style: none;
          padding: 0;
          margin: 0;
          font: 18px;
        }

     

 

      
      `}</style>
    </footer>
  );
};

export default Footer;
