
import React, {  useEffect } from "react";
import axios from "axios";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import "../css/abhaCard.css";
import nha_logo from "../assets/image/abha/nha_logo.svg";
import dm_logo from "../assets/image/abha/dm_logo.svg";
import barcodeImg from "../assets/image/abha/barcode.png";
import Swal from 'sweetalert2';


const AbhaCard = () => {
  const abhaCardDetails = JSON.parse(localStorage.getItem("abhaCardDetails"));
  const abhaProfileImage = localStorage.getItem("abhaProfileImage");

 
  useEffect(() => {
    const currentDate = new Date().toISOString(); // Ensure the date is in the correct format

    const storeAbhaDetails = async () => {
      try {
        // Create a FormData object
        const formData = new FormData();
        formData.append('abha_address', abhaCardDetails.healthIdNumber);
        formData.append('abha_id', abhaCardDetails.healthId);
        formData.append('name', abhaCardDetails.name);
        formData.append('gender', abhaCardDetails.gender);
        formData.append('dob', `${abhaCardDetails.yearOfBirth}-${abhaCardDetails.monthOfBirth}-${abhaCardDetails.dayOfBirth}`);
        formData.append('created_by', 'Website');
        formData.append('created_at', currentDate);

        const response = await axios.post(
          "https://claim.easeclaim.com/app/store_abha_details",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        // console.log(response);
        if (response.data.status === true) {
          Swal.fire({
            icon: 'success',
            title: 'Details Stored',
            text: 'ABHA details have been successfully stored.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Storage Failed',
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Storage Failed',
          text: 'Failed to store ABHA details. Please try again.',
        });
        console.error("Error storing ABHA details:", error);
      }
    };

    if (abhaCardDetails) {
      storeAbhaDetails();
    }
  }, [abhaCardDetails]);


  const downloadAbhaCard = () => {
    const input = document.getElementById('abha-card-download');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('abha_card.pdf');
    });
  };

  return (
    <>
      <div className="container container-abdm ">
      <div className="row">
        <div className="col-4">
          <button className="btn-active" onClick={downloadAbhaCard}>
            Download ABHA Card
          </button>
        </div>
         
        </div>
        <div className="row main-box">
          <div className="col-md-8" id="abha-card-download">
            <div className="abha-card my-5">
              <div className="card-header">
                <div className="header-left-logo">
                  <img src={nha_logo} alt="nha logo" />
                </div>
                <div className="header-center">
                  <div className="abha-number-header-title">
                    Ayushman Bharat Health Account (ABHA)
                  </div>
                  <span class="abha-number-header-subtitle">
                    आयुष्मान भारत स्वास्थ्य खाता (आभा)
                  </span>
                  {/* <h3></h3>
                        <h6></h6> */}
                </div>
                <div className="header-right">
                  <img src={dm_logo} alt="digital mission" />
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="profile-img">
                      <img
                        src={abhaProfileImage}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 text-start">
                    <div className="title-body">
                      <h6 class="card-title">Name/नाम</h6>
                      <span className="holder-name">
                        {abhaCardDetails.name}
                      </span>
                    </div>
                    <div className="title-body">
                      <h6 class="card-title">ABHA number/आभा-संख्या</h6>
                      <span class="abha-number">
                        {abhaCardDetails.healthIdNumber}
                      </span>
                    </div>
                    <div className="title-body">
                      <h6 class="card-title">ABHA address/आभा पता</h6>
                      <span class="abha-number">
                        {abhaCardDetails.healthId}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="profile-img">
                      <img
                        src={barcodeImg}
                        alt="barcode img"
                        className="img-fluid"
                      />
                    </div>
                  </div>

                  <div className="row text-start">
                    <div className="col-md-3 d-flex justify-content-center">
                      <div className="title-body">
                        <h6 class="card-title">Gender/लिंग</h6>
                        <span class="abha-number">
                          {abhaCardDetails.gender}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="title-body">
                        <h6 class="card-title">Date of birth/जन्मतिथि</h6>
                        <span class="abha-number">
                          {abhaCardDetails.dayOfBirth}-
                          {abhaCardDetails.monthOfBirth}-
                          {abhaCardDetails.yearOfBirth}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3  d-flex justify-content-center">
                      <div className="title-body">
                        <h6 class="card-title">Mobile/मोबाइल</h6>
                        <span class="abha-number">
                          {abhaCardDetails.mobile}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="abha-card my-3">
              <div className="card-header">
                <div className="header-left-logo">
                  <img src={nha_logo} alt="nha logo" />
                </div>
                <div className="header-center">
                  <div className="abha-number-header-title">
                    Ayushman Bharat Health Account (ABHA)
                  </div>
                  <span class="abha-number-header-subtitle">
                    आयुष्मान भारत स्वास्थ्य खाता (आभा)
                  </span>
                  {/* <h3></h3>
                        <h6></h6> */}
                </div>
                <div className="header-right">
                  <img src={dm_logo} alt="digital mission" />
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="top-info">
                    <span>
                      <strong>Instructions</strong>
                    </span>
                    <span>
                      <strong>Toll-Free Number: 1800 114 477</strong>
                    </span>
                  </div>
                  <div className="instruction-list">
                    <ul className="info-list">
                      <li>
                        With this ABHA you have become a part of India's digital
                        health ecosystem. इस आभा के साथ आप भारत के डिजिटल हेल्थ
                        इकोसिस्टम का हिस्सा बन गए हैं।
                      </li>
                      <li>
                        You can download the ABHA mobile app, Aarogya Setu or
                        other ABDM enabled app to view and share your digital
                        health records with ABDM registered healthcare service
                        providers. आप एबीडीएम पंजीकृत स्वास्थ्य सेवा प्रदाताओं
                        के साथ अपने डिजिटल स्वास्थ्य रिकॉर्ड देखने और साझा करने
                        के लिए आभा मोबाइल ऐप, आरोग्य सेतु या अन्य एबीडीएम सक्षम
                        ऐप डाउनलोड कर सकते हैं।
                      </li>
                      <li>
                        ABHA provides you a unique identification and helps in
                        storing - safekeeping all your digitalhealth records at
                        one place. आभा आपको एक विशिष्ट पहचान प्रदान करता है और
                        आपके सभी डिजिटल स्वास्थ्य रिकॉर्ड को सुरक्षित एक ही
                        स्थान पर संग्रहीत रखने में मदद करता है।{" "}
                      </li>
                      <li>
                        If this card is lost kindly download it from
                        www.abha.abdm.gov.in, it is digitally acceptable. यदि यह
                        कार्ड खो जाता है तो कृपया इसे www.abha.abdm.gov.in से
                        डाउनलोड करें, यह डिजिटल रूप से स्वीकार्य है।{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-5 py-5">
            <div className="right-btn-sec">
              <button className="btn-success-dark">
                <span className="step-number">1</span>Consent Collection
              </button>
              <button className="btn-success-dark">
                <span className="step-number">2</span>Aadhaar Authentication
              </button>
              <button className="btn-success-dark">
                <span className="step-number">3</span>Communication Details
              </button>
              <button className="btn-success-dark">
                <span className="step-number">4</span>ABHA Address Creation
              </button>
              <button className="btn-active">
                <span className="step-number">5</span>ABHA Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AbhaCard;
