import React from "react";
import { Link } from "react-router-dom";
import mailIcon from "../images/mail-icon.svg";
import telIcon from "../images/tele-icon.svg";
import whatsappIcon from "../images/whatsapp.svg";
import xIcon from "../assets/image/banner/x.png";
import logo from "../images/home/banner/logo.svg";
import arrow from "../images/home/step/arrow.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  return (
    <>
      <header>
        <div className="top-bar mobile-dis-n">
          <div className="container">
            <div className="top-bar-main">
              <div className="top-bar-l">
                <ul>
                  <li>
                    <Link to="mailto:info@easeclaim.com">Get Support</Link>
                  </li>
                  <li className="pipe-content">
                    <Link to="mailto:info@easeclaim.com">
                      <img src={mailIcon} alt="easeclaim Email " />
                      info@easeclaim.com
                    </Link>
                    <div className="pipe"></div>
                  </li>
                  <li className="pipe-content">
                    <Link to="tel:+91 93101 21772">
                      {" "}
                      <img src={telIcon} alt="Reach us at +91 93101 21772" />
                      &nbsp;+91 931-012-1772
                    </Link>
                    <div className="pipe"></div>
                  </li>
                  <li>
                    <Link
                      to="https://api.whatsapp.com/send?phone=+918506884052&amp;text=Hi"
                      target="_blank"
                    >
                      <img src={whatsappIcon} alt="easeclaim Whatsapp Number" />
                      &nbsp;850-688-4052
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="top-bar-r">
                <ul className="social-footer">
                  <li>
                    <Link to="https://www.facebook.com/profile.php?id=100094298285693">
                      <FontAwesomeIcon icon={faFacebook} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        className="x-icon"
                        src={xIcon}
                        width="15"
                        alt="easeclaim Twitter"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/company/ease-claim">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/easeclaim/">
                      <FontAwesomeIcon icon={faInstagram} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <nav className="navbar navbar-expand-lg navbar-light navigation-1">
              <div className="container-fluid">
                {" "}
                <Link className="navbar-brand logo" to="">
                  <img src={logo} alt="easeclaim's logo" border="0" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarNavDropdown"
                >
                  <ul className="navbar-nav nav me-auto mb-2 mb-lg-0 navbar-nav-2">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to=""
                      >
                        Home
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className="nav-link"
                        to="/#our-services"
                        role="button"
                      >
                        {" "}
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="/abha">
                        ABHA
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="/about-us">
                        About us
                      </Link>{" "}
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="/blogs">
                        Blogs
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="/contact-us">
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="/partners">
                        Partnership
                      </Link>{" "}
                    </li>
                  </ul>
                  <div className="d-flex top-bar-r-btn">
                    {" "}
                    <Link to="/file-claim">
                      File Your Claim
                      <span>
                        &nbsp;&nbsp;
                        <img src={arrow} alt="Arrow" />
                      </span>
                    </Link>
                    <Link
                      to="https://claim.easeclaim.com/"
                      className="select-1"
                      target="_blank"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
